import React from "react";
import {useNavigate} from 'react-router-dom';
import './Home.css';
import banner from '../../Images/banner_k.png';

const HomePage = () => {
  const navigate = useNavigate();
  sessionStorage.clear();

  const navigateToPage = (route) => {
    navigate(route);
  }

  const openInApp = (appUrl, webUrl) => {
    window.location = appUrl;
    setTimeout(() => {
      window.location = webUrl;
    }, 500);
  }

  return (
    <main>
      <a href={"https://ko-fi.com/solstoid"} target="_blank" rel="noopener noreferrer">
        <div className={"banner blueRow"}>
          <img src={banner} title={"Support Social Battle Royale with a donation on KoFi"} alt={"Support me by donating on KoFi"}/>
        </div>
      </a>
      <div className={"homePageContainer"}>
        <h1>Social Battle Royale</h1>
        <h3 className={"centered"}>The first game playable by Instagram comments!</h3>
        <span className={"bigMenuItem"} onClick={() => navigateToPage('/scoreboard')}>
        <h2>Latest Scoreboard</h2>
        <h4>Click here to see the last battle's results</h4>
      </span>
        <span className={"bigMenuItem"} onClick={() => navigateToPage('/battles-history')}>
        <h2>Previous Battles</h2>
        <h4>Click here to look at older battles' results</h4>
      </span>
        <span className={"bigMenuItem"} onClick={() => navigateToPage('/character-customization')}>
        <h2>Character Editor</h2>
        <h4>Click here to create your character</h4>
      </span>
        <span className={"bigMenuItem"} onClick={() => navigateToPage('/user/')}>
        <h2>User Stats</h2>
        <h4>Click here to view your player stats</h4>
      </span>
        <span className={"bigMenuItem"} onClick={() => openInApp('https://www.instagram.com/social.battle.royale/')}>
        <h2>Instagram Battles</h2>
        <h4>Click here to reach out the official Social Battle Royale account</h4>
      </span>
        <span className={"bigMenuItem"} onClick={() => openInApp('https://discord.com/invite/B4SAU3eBzm')}> <h2>Discord Server</h2>
        <h4>Click here to join the official Social Battle Royale server</h4>
      </span>
        <span className={"bigMenuItem"} onClick={() => openInApp('https://twitch.tv/solstoid')}> <h2>Twitch Channel</h2>
        <h4>Click here to check out my Twitch Channel</h4>
      </span>
        <h4>When I'll have time I'll improve the website graphics. I update the game every day, stay tuned.</h4>
        <p onClick={() => navigateToPage('/battles-simulator')} style={{alignSelf: "flex-end"}}>Ver 0.9.4</p>
      </div>
    </main>
  );
};

export default HomePage;